export const API_HOST =
  process.env.REACT_APP_API_HOST || "http://localhost:8000";

export const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;

export const REACT_APP_LOGO_IMAGE_NAME =
  process.env.REACT_APP_LOGO_IMAGE_NAME || "empty_error";

export const REACT_APP_RTU_SERVER_IP =
  process.env.REACT_APP_RTU_SERVER_IP || "121.139.19.59";
